// @ts-nocheck
import { useState, useCallback } from "react";

const useToggle = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);
  const toggleValue = useCallback(() => setValue((existing) => !existing), [
    setValue
  ]);
  return [value, toggleValue];
};
export default useToggle;
